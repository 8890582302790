<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <!-- Reset Password v1 -->
      <b-card class="mb-0">

        <!-- logo -->
        <b-link class="brand-logo">
          <vuexy-logo />

          <h2 class="brand-text text-primary ml-1">
            LoxCloud
          </h2>
        </b-link>

        <b-card-title class="mb-1">
          邮箱验证
        </b-card-title>
        <b-card-text class="mb-2">
          请点击按钮进行邮箱验证
        </b-card-text>

        <!-- form -->
        <validation-observer ref="simpleRules">
          <b-form
            method="POST"
            class="auth-reset-password-form mt-2"
            @submit.prevent="validationForm"
          >

            <!-- submit button -->
            <b-button
              block
              type="submit"
              variant="primary"
              @click="submit"
            >
              验证邮箱
            </b-button>
          </b-form>
        </validation-observer>

        <p class="text-center mt-2">
          <b-link :to="{name:'auth-login'}">
            <feather-icon icon="ChevronLeftIcon" /> Back to login
          </b-link>
        </p>

      </b-card>
    <!-- /Reset Password v1 -->
    </div>
  </div>

</template>

<script>
/* eslint-disable global-require */
import { ValidationObserver } from 'vee-validate'
import useJwt from '@/auth/jwt/useJwt'
import { notify, throwRes } from '@/utils/utils'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BCardTitle, BCardText, BForm, BLink, BButton,
} from 'bootstrap-vue'
import { required } from '@validations'
import store from '@/store/index'

export default {
  components: {
    VuexyLogo,
    BButton,
    BCardTitle,
    BCardText,
    BForm,
    BLink,
    ValidationObserver,
  },
  data() {
    return {
      token: '',
      sideImg: require('@/assets/images/pages/reset-password-v2.svg'),
      // validation
      required,
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/reset-password-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  mounted() {
    this.token = this.$route.params.token
  },
  methods: {
    validationForm() {
    },
    submit() {
      useJwt.emailVerify(this.token).then(res => {
        throwRes(res)
        notify('OK', '成功')
      }).catch(error => {
        throwRes(error)
        notify('失败', '错误', 'danger')
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
